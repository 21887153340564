import { message } from "antd";
import axios from "axios";
import { loginURL, oemKey } from "../config/websiteConfig";
import { deleteToken } from "./Utility";
const TOKEN = window.localStorage.getItem("token");
const rest = {
  get,
  post,
  put,
  remove,
  makeQueryUrlParam,
};

export default rest;

//前置 配置 config
// axios.interceptors.request.use(config =>{
//     console.log('config', config)
//     if(config.method=='post'){
//         config.data = {}
//     }else if(config.method=='get'){
//         config.params = {}
//     }
//    return config;
//    },err =>{
//      return Promise.reject(err);
// })

//重定向到登录
export function redirectLogin() {
  if (oemKey !== "PRIVATE") {
    deleteToken();
    return (window.location.href = loginURL);
  }
}

//axios拦截器(异常拦截)
axios.interceptors.response.use(
  function (response) {
    let res = response && response.data;
    if (response && response.status === 200) {
      if (res && res.code === 403) {
        //token过期重新登录
        message.error(res && res.message);
        redirectLogin();
      }
    } else if (response && response.status === 403) {
      res && message.error(res && res.message);
      //token过期重新登录
      redirectLogin();
    } else {
      return message.error((res && res.message) || "网络错误");
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

function rejectData(response) {
  let errCode = response && response.response && response.response.data;
  if (errCode) {
    if (errCode.code === 403) {
      redirectLogin();
    }
    throw errCode;
  }
  throw response;
}

function respondData(response) {
  if (!response) {
    return null;
  }
  return response && response.data;
}

//设置headers
function setHeaders(myHeader) {
  let config = {};
  //自定义 header
  if (myHeader) {
    config = myHeader;
  } else {
    if (TOKEN) {
      config = { headers: { "x-access-token": TOKEN } };
    } else {
      // return (window.location.href = loginURL);
      return redirectLogin();
    }
  }

  //返回其他状态码
  Object.assign(config, {
    validateStatus: function (status) {
      return status;
    },
  });

  return config;
}

function get(url, header) {
  url = _concatUrl(url);
  let config = setHeaders(header);

  return axios.get(url, config).then(respondData).catch(rejectData);
}

function post(url, data = {}, header) {
  url = _concatUrl(url);
  let config = setHeaders(header);
  return axios.post(url, data, config).then(respondData).catch(rejectData);
}

function put(url, data = {}, header) {
  url = _concatUrl(url);
  let config = setHeaders(header);
  return axios.put(url, data, config).then(respondData).catch(rejectData);
}

function remove(url, header) {
  url = _concatUrl(url);
  let config = setHeaders(header);
  return axios.delete(url, config).then(respondData).catch(rejectData);
}

function _concatUrl(url) {
  return url;
}

function makeQueryUrlParam(filter = {}, only_count) {
  let param = "";

  if (filter.where) {
    param += "&where=" + encodeURIComponent(JSON.stringify(filter.where));
  }

  if (filter.unRead) {
    param += "&unRead=" + filter.unRead;
  }

  if (filter.skip) {
    param += "&skip=" + filter.skip;
  }

  if (filter.limit) {
    param += "&limit=" + filter.limit;
  }
  if (filter.order) {
    param += "&order=" + filter.order;
  }

  if (filter.onlyCount) {
    param += "&only_count=true";
  }

  if (filter.expand) {
    param += "&expand=" + filter.expand;
  }
  if (filter.type) {
    param += "&type=" + filter.type;
  }

  // materials:
  if (filter.traced) {
    param += "&traced=true";
  }

  if (filter.traces_contains_picture) {
    param += "&traces_contains_picture=true";
  }

  return param;
}
