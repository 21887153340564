import { oemKey } from "../common/config/websiteConfig";
import { AsyncComponent } from "../common/utils/AsyncComponent";
export const routers =
  oemKey !== "PRIVATE"
    ? [
        {
          path: "/",
          exact: true,
          isNav: true,
          title: "首页",
          component: AsyncComponent(() => import("../pages/home")),
        },
        {
          path: "/function",
          exact: true,
          isNav: true,
          title: "功能介绍",
          component: AsyncComponent(() => import("../pages/function")),
        },
        {
          path: "/example",
          exact: true,
          isNav: true,
          title: "BIM体验",
          component: AsyncComponent(() => import("../pages/example")),
        },
        {
          path: "/cases",
          exact: true,
          isNav: true,
          title: "客户案例",
          component: AsyncComponent(() => import("../pages/cases")),
        },
        {
          path: "/documents",
          exact: true,
          isNav: true,
          isSubMenu: true,
          title: "文档中心",
          component: AsyncComponent(() => import("../pages/download")),
        },
        {
          path: "/about",
          exact: true,
          isNav: true,
          title: "关于我们",
          component: AsyncComponent(() => import("../pages/about")),
        },
        {
          path: "/devGuide",
          exact: true,
          // isNav: true,
          title: "开发指南",
          component: AsyncComponent(() => import("../pages/devGuide")),
        },
        {
          path: "/exampleDemo",
          exact: true,
          // isNav:true,
          title: " 功能示例",
          component: AsyncComponent(() => import("../pages/exampleDemo")),
        },
        {
          path: "/downloads",
          exact: true,
          // isNav:true,
          title: "资源下载",
          component: AsyncComponent(() => import("../pages/download")),
        },
        {
          path: "/changeLog",
          exact: true,
          title: "更新日志",
          component: AsyncComponent(() => import("../pages/changeLog")),
        },
        {
          path: "/odaTestModel",
          exact: true,
          title: "模型预览-oda测试",
          component: AsyncComponent(() => import("../pages/model")),
        },
        {
          path: "/odaTestDrawing",
          exact: true,
          title: "图纸预览-oda测试",
          component: AsyncComponent(() => import("../pages/drawing")),
        },
        {
          path: "/modelEffect",
          exact: true,
          title: "场景-模型效果",
          component: AsyncComponent(() =>
            import("../pages/scene/module/modelEffect")
          ),
        },
        {
          path: "/linkage",
          exact: true,
          title: "场景-图模联动",
          component: AsyncComponent(() =>
            import("../pages/scene/module/linkage")
          ),
        },
        {
          path: "/versionCompare",
          exact: true,
          title: "场景-版本对比",
          component: AsyncComponent(() =>
            import("../pages/scene/module/versionCompare")
          ),
        },
        {
          path: "/BIMPlatform",
          exact: true,
          title: "场景-BIM协同应用",
          component: AsyncComponent(() =>
            import("../pages/scene/module/BIMPlatform")
          ),
        },
        {
          path: "/BIMInternet",
          exact: true,
          title: "场景-BIM+物联网",
          component: AsyncComponent(() =>
            import("../pages/scene/module/BIMInternet")
          ),
        },
        {
          path: "/digitalMap",
          exact: true,
          title: "场景-图纸在线管理",
          component: AsyncComponent(() =>
            import("../pages/scene/module/digitalMap")
          ),
        },
        {
          path: "/backFileList",
          exact: true,
          title: "后台文件列表",
          component: AsyncComponent(() =>
            import("../pages/BackgroundFileList/BackgroundFileList")
          ),
        },
      ]
    : [
        {
          path: "/",
          exact: true,
          isConsole: true,
          title: "图形引擎管理",
          component: AsyncComponent(() => import("../developer/console")),
        },
        {
          path: "/verifyPhone",
          exact: true,
          title: "修改手机号",
          component: AsyncComponent(() => import("../developer/verifyPhone")),
        },
        {
          path: "/login",
          exact: true,
          title: "登录",
          component: AsyncComponent(() => import("../pages/user/Login/login")),
        },
        {
          path: "/findPassword",
          exact: true,
          title: "找回密码",
          component: AsyncComponent(() =>
            import("../pages/user/PasswordFind/passwordFind")
          ),
        },
        {
          path: "/register",
          exact: true,
          title: "找回密码",
          component: AsyncComponent(() =>
            import("../pages/user/Register/register")
          ),
        },
      ];

export const publicRouters = [
  {
    path: "/model",
    exact: true,
    title: "模型预览-引擎官网",
    component: AsyncComponent(() => import("../pages/bimModel/bimModel")),
  },
  {
    path: "/drawing",
    exact: true,
    title: "图纸预览-引擎官网",
    component: AsyncComponent(() => import("../pages/bimDrawing/bimDrawing")),
  },
  {
    path: "/gis",
    exact: true,
    title: "场景预览-引擎官网",
    component: AsyncComponent(() => import("../pages/bimGIS/bimGIS")),
  },
  {
    path: "/thing",
    exact: true,
    title: "物品预览-引擎官网",
    component: AsyncComponent(() => import("../pages/bimThing/bimThing")),
  },
];
