import moment from "moment";
import "moment/locale/zh-cn";
import React from "react";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Route } from "react-router-dom";
import packageJson from "../package.json";
import { oemKey } from "./common/config/websiteConfig";
import "./index.css";
import App from "./router";
import * as serviceWorker from "./serviceWorker";
import store from "./store/index";
moment.locale("zh-cn");
let renderEle = document.getElementById("root");
oemKey === "PRIVATE"
  ? ReactDOM.render(<App />, document.getElementById("root"))
  : ReactDOM.render(
      <Provider store={store}>
        <BrowserRouter>
          <Route path="/" component={App} />
        </BrowserRouter>
      </Provider>,
      renderEle
    );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

console.log(
  `Copyright © 译筑信息科技(上海)有限公司 EveryBIM 图形引擎: V${
    packageJson.version + `.${oemKey}.202401230`
  }`
);
