import { loginURL, oemKey } from "../config/websiteConfig";
export function deleteToken() {
  window.localStorage.removeItem("token");
}
//重定向到登录
export function redirectLogin() {
  deleteToken();
  if (oemKey === "PRIVATE") {
    window.location.href = "/login";
  } else {
    window.location.href = loginURL;
  }
}
/**
 * 文件大小格式转化
 * @param
 * @returns
 */
export function renderSize(value) {
  if (value === null || value === "" || value === 0) {
    return "0";
  }
  const unitArr = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let renderSize = 0;
  let fileSize = parseFloat(value);
  renderSize = Math.floor(Math.log(fileSize) / Math.log(1024));
  let size = fileSize / Math.pow(1024, renderSize);
  size = size.toFixed(2); //保留的小数位数

  return size + unitArr[renderSize];
}

/**
 * 时间显示格式转化
 * @param
 * @returns
 */
export function switchTimeFormat(time) {
  const dateTime = new Date(time);
  const year = dateTime.getFullYear();
  const month = dateTime.getMonth() + 1;
  const date = dateTime.getDate();
  const hour = dateTime.getHours();
  const minute = dateTime.getMinutes();
  const second = dateTime.getSeconds();
  const addZero = (v) => {
    return v < 10 ? "0" + v : v;
  };
  const timeFormat =
    year +
    "-" +
    addZero(month) +
    "-" +
    addZero(date) +
    " " +
    addZero(hour) +
    ":" +
    addZero(minute) +
    ":" +
    addZero(second);
  return timeFormat;
}

/**
 * 获取两个时间的时间差
 * @param
 * @returns
 */
export function getTimePeriod(begin, end) {
  let beginTime = switchTimeFormat(begin);
  let endTime = switchTimeFormat(end);

  let beginTime_ms = Date.parse(new Date(beginTime.replace(/-/g, "/"))); //begintime 为开始时间
  let endTime_ms = Date.parse(new Date(endTime.replace(/-/g, "/"))); // endtime 为结束时间

  let usedTime = endTime_ms - beginTime_ms; // 相差的毫秒数

  //let days = Math.floor(usedTime / (24 * 3600 * 1000)); // 计算出天数

  let leavel = usedTime % (24 * 3600 * 1000); // 计算天数后剩余的时间
  let hours = Math.floor(leavel / (3600 * 1000)); // 计算剩余的小时数

  let leavel2 = leavel % (3600 * 1000); // 计算剩余小时后剩余的毫秒数
  let minutes = Math.floor(leavel2 / (60 * 1000)); // 计算剩余的分钟数

  let leavel3 = leavel2 % (60 * 1000); //计算分钟数后剩余的毫秒数
  let seconds = Math.floor(leavel3 / 1000);
  return hours + "时" + minutes + "分" + seconds + "秒";
}
/**
 * 根据某个时间以及时间差获取新的时间
 * dateFirst: YYYY-MM-DD格式  days: number
 * @param
 * @returns
 */
export function getNewDate(dateFirst, days) {
  let dateTemp = dateFirst.split("-");
  let nDate = new Date(dateTemp[1] + "-" + dateTemp[2] + "-" + dateTemp[0]); //转换为MM-DD-YYYY格式
  let millSeconds = Math.abs(nDate) + days * 24 * 60 * 60 * 1000;
  let rDate = new Date(millSeconds);
  let year = rDate.getFullYear();
  let month = rDate.getMonth() + 1;
  if (month < 10) month = "0" + month;
  let date = rDate.getDate();
  if (date < 10) date = "0" + date;
  return year + "-" + month + "-" + date;
}
/**
 * 保留 N 位小数点 四舍五入
 * number: 传入值  n: 表示位数
 * @param
 * @returns
 */
export function getFloat(number, n) {
  n = n ? parseInt(n) : 0;
  if (n <= 0) return Math.round(number);
  number = Math.round(number * Math.pow(10, n)) / Math.pow(10, n);
  return number;
}

/**
 * 获取文件后缀名
 * fileName: 文件名称
 * @param
 * @returns
 */
export function getFileName(fileName) {
  let fileFormat = "";
  if (fileName) {
    let index = fileName.lastIndexOf("."); //取到文件名开始到最后一个点的长度
    fileFormat = fileName.substring(index + 1, fileName.length); //截取文件名后缀
    return fileFormat;
  }
}

/**
 * 解析url中的search字符串转换成对象
 * @param
 * @returns
 */
export function getURLValueByKey(key) {
  let url = window.location.search;
  let reg = /[?&][^?&]+=[^?&]+/g;
  let arr = url.match(reg);
  if (arr) {
    // 生成key value对象
    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      let tmpArr = item.substring(1).split("=");
      let tmpKey = decodeURIComponent(tmpArr[0]);
      if (tmpKey === key) {
        let value = decodeURIComponent(tmpArr[1]);
        return value;
      }
    }
    // 没有匹配到返回空
    return "";
  } else {
    // url不包含key值 返回空
    return "";
  }
}
// 生成随机字符串
export function randomString(len) {
  len = len || 32;
  let $chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let maxPos = $chars.length;
  let pwd = "";
  for (let i = 0; i < len; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}
//创建uuid
export function uuid24() {
  return "xxxxxxxxxxxxxxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
//数组转换成字符串
export function getTextByJs(arr, isDot) {
  let str = "";
  let len = arr && arr.length;
  for (let i = 0; i < len; i++) {
    if (isDot) {
      str += "." + arr[i] + ",";
    } else {
      str += arr[i] + ",";
    }
  }
  //去掉最后一个逗号
  if (str.length > 0) {
    str = str.substr(0, str.length - 1);
  }
  return str;
}

//格式转换 ["dwg"] => [".dwg"]
export function getSupportByJs(bimSupport) {
  return bimSupport
    ?.filter((i) => i !== "zip")
    .map((extension) => `.${extension}`);
}
//打开新窗口(避免浏览器阻拦)
export function openWindow(url) {
  //let tempWindow = window.open('_blank'); // 先打开页面
  // QQ浏览器有时候还是会阻拦，当阻拦之后，得不到tempWindow，折中使用当前页面打开
  //(tempWindow || window).location = url; // 后更改页面地址
  window.open(url, "_blank");
}

//判断是否是 PC 端
export function isPC() {
  let userAgentInfo = navigator.userAgent;
  let Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPod"];
  let flag = true;
  for (let v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false;
      break;
    }
  }
  if (window.screen.width >= 768) {
    flag = true;
  }
  return flag;
}

// /**
//  * 函数防抖
//  * @param func
//  * @param wait
//  * @param immediate
//  * @returns {debounced}
//  */
// export function debounce(func, wait, immediate) {
//   var timeout, result;
//   var debounced = function () {
//       var context = this;
//       var args = arguments;

//       if (timeout) clearTimeout(timeout);

//       if (immediate) {
//           var callnow = !timeout;
//           timeout = setTimeout(function () {
//               timeout = null;
//           }, wait);
//           if (callnow) result = func.apply(context, args);
//       } else {
//           timeout = setTimeout(function () {
//               func.apply(context, args);
//           }, wait);
//       }
//       return result;
//   };

//   debounced.cancel = function () {
//       clearTimeout(timeout);
//       timeout = null;
//   };

//   return debounced;
// };

// /**
// // * 函数节流 鼠标移入能立刻执行，停止触发的时候还能再执行一次！
// // * @param func 回调函数
// // * @param wait 延时时间
// // * @param options leading:false禁用第一次执行,trailing: false 禁用结束后再执行一次
// // * @returns {throttled}
// // */
// // export function throttle(func, wait, options) {
// //   var timeout, context, args;
// //   var previous_time = 0;
// //   if (!options) options = {};

// //   var later = function () {
// //       //leading为false将初始时间设为0
// //       previous_time = options.leading === false ? 0 : Date.now();
// //       timeout = null;
// //       func.apply(func, args);
// //       if (!timeout) context = args = null;
// //   };

// //   var throttled = function () {
// //       var now_time = Date.now();
// //       if (!previous_time && options.leading === false) previous_time = now_time;
// //       //下次触发func的剩余时间
// //       var remaining = wait - (now_time - previous_time);
// //       context = this;
// //       args = arguments;
// //       // 如果没有剩余的时间了或者你改了系统时间,则进行首次执行
// //       if (remaining <= 0 || remaining > wait) {
// //           if (timeout) {
// //               clearTimeout(timeout);
// //               timeout = null;
// //           }
// //           previous_time = now_time;
// //           func.apply(context, args);
// //           if (!timeout) context = args = null;
// //       } else if (!timeout && options.trailing !== false) {
// //           //条件满足，利用延时函数在结束后再执行一次
// //           timeout = setTimeout(later, remaining);
// //       }
// //   };

// //   throttled.cancel = function () {
// //       clearTimeout(timeout);
// //       previous_time = 0;
// //       timeout = null;
// //   };

// //   return throttled;
// // }

/**
 * 获取数据类型
 * @param {*} obj
 */
function getTypeByObj(obj) {
  return Object.prototype.toString
    .call(obj)
    .match(/^\[object ([a-zA-Z]*)\]$/)[1];
}
/**
 * 判断是否是空对象
 * @param {*} obj
 */
function isEmptyObject(obj) {
  for (var key in obj) {
    return false;
  }
  return true;
}

//判断两个数组是否一致
export function diffObject(json1, json2) {
  if (!json1 || isEmptyObject(json1) || !json2 || isEmptyObject(json2)) {
    return null;
  }
  let diffRes = {
    old_val: {},
    new_val: {},
  };
  for (let k in json2) {
    // 判断数据类型是否一致
    if (getTypeByObj(json2[k]) === getTypeByObj(json1[k])) {
      // 比较 “Array”和“Object”类型
      if (
        getTypeByObj(json2[k]) === "Array" ||
        getTypeByObj(json2[k]) === "Object"
      ) {
        const diffData = diffObject(json1[k], json2[k]);
        if (!isEmptyObject(diffData)) {
          diffRes.old_val[k] = diffData.old_val;
          diffRes.new_val[k] = diffData.new_val;
        }
      } else if (json1[k] !== json2[k]) {
        // 比较其他类型数据
        diffRes.old_val[k] = json1[k];
        diffRes.new_val[k] = json2[k];
      }
    } else {
      diffRes.old_val[k] = json1[k];
      diffRes.new_val[k] = json2[k];
    }
  }
  // 若没有变化，返回null
  if (isEmptyObject(diffRes.old_val) || isEmptyObject(diffRes.new_val)) {
    return null;
  }
  return diffRes;
}

//过滤对象中不需要的 key
export function filterObject(obj, arr) {
  if (typeof obj !== "object" || !Array.isArray(arr)) {
    throw new Error("参数格式不正确");
  }
  const result = {};
  Object.keys(obj)
    .filter((key) => !arr.includes(key))
    .forEach((key) => {
      result[key] = obj[key];
    });
  return result;
}
